
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import gdbx from '@/store/modules/gdbx';
import LastSync from '@/components/LastSync.vue';
import BasePageContainer from '../BasePageContainer.vue';
import ToDeliver from '@/components/reports-v2/components/codedWidgets/sales/ToDeliver.vue';
import FulfilledSalesOrder from '@/components/reports-v2/components/codedWidgets/sales/FulfilledSalesOrder.vue';

@Component({
  components: {
    StickyFilterContainer,
    ToDeliver,
    FulfilledSalesOrder,
    DateFilter,
    DateRangeFilter,
    LastSync,
  },
})
export default class SalesDelivery extends BasePageContainer {
  public selectedDay: number = moment().endOf('day').valueOf();

  public selectedDateRange: [number, number] = [
    moment().add(-3, 'months').valueOf(),
    moment().valueOf(),
  ];

  public mounted() {
    this.track();
  }

  public track() {
    this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
    this.$ga.page(router);
  }

  public get selectedAsOfDate() {
    return moment().add( 1, 'days').valueOf();
  }

  public get selectedAgents() {
    return gdbx.allAgents.map((vt) => vt.value);
  }

  public get selectedStockItems() {
    return gdbx.allStockItems.map((vt) => vt.value);
  }

  public get selectedCustomers() {
    return gdbx.allCustomers.map((vt) => vt.value);
  }

  public onHovering(value: boolean, filterId: string) {
    this.$emit('hovering', value, filterId);
  }
  public onEditing(value: boolean, filterId: string) {
    this.$emit('editing', value, filterId);
  }
}
