
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';
import {nestedGroupBy} from '@/util/nestedGroupBy';
import gdbx from '@/store/modules/gdbx';

@Component({
  components: {
    TableFilterable,
  },
})
export default class ToDeliver extends FilterWidget {
  public filterIds: Array<
    | 'date'
    | 'dateAsOf'
    | 'dateRange'
    | 'stockItems'
    | 'agents'
    | 'customers'
    | 'suppliers'
  > = [];

  public tableItems: any[] = [];
  public tableFields: any[] = [];

  public detailFields: any[] = [];

  public infoModal = {
    id: 'info-modal2',
    index: 0,
    title: '',
    extras: '',
    amount: '',
    docDate: 0,
    os_amount: '',
    items: [] as any[],
  };

  public info(item, extras, index, title, amount, osAmount, link) {
    this.infoModal.title = title + ' ' + '( ' + item.items[0].customer + ' )';
    this.infoModal.extras = extras;
    this.infoModal.index = index;
    this.infoModal.amount = String(Math.abs(amount));
    this.infoModal.os_amount = (osAmount);
    this.infoModal.docDate = item.items[0].docDate;
    this.infoModal.items = item.items;
    this.$root.$emit('bv::show::modal', this.infoModal.id, link);
  }

  public resetInfoModal() {
    this.infoModal.title = '';
    this.infoModal.extras = '';
    this.infoModal.items = [];
  }

  public get modifiedDate(): [number, number] {
    return [
        moment(this.selectedAsOfDate).add(-3, 'months').valueOf(),
        this.selectedAsOfDate,
    ];
  }

  public get permissionIds(): PermissionsGroup[] {
    return ['sales'];
  }

  public get exportFileName() {
    return 'Top Customer' + '_' + this.monthName + ' ' + this.yearName;
  }

  public get currentMonth() {
    return moment(this.selectedAsOfDate).month();
  }

  public get currentYear() {
    return moment(this.selectedAsOfDate).year();
  }

  public get monthName() {
    return moment().month(this.currentMonth).format('MMM').toUpperCase();
  }

  public get yearName() {
    return moment().year(this.currentYear).format('YY').toUpperCase();
  }


  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      selectedStockItems,
      selectedCustomers,
      selectedAgents,

    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      selectedStockItems,
      selectedCustomers,
      selectedAgents,
    ]);
  }

  public async expensiveCalc() {

    const tradeRef = FilteredDatabase.ref('salesOrder')
        .dateRange(this.modifiedDate[0] ? this.modifiedDate : this.selectedDateRange, 'delivery_date');

    const customerRef = FilteredDatabase.ref('customers')
        .agents(this.selectedAgents);

    const tradeCards = await (await tradeRef.get()).getCards();

    const customerCards = await (await customerRef.get()).getCards();

    const groupedCards = nestedGroupBy(tradeCards, ['docNo']);

    const result: any = [];

    for (const group in groupedCards) {

      if (group) {
        const cards = groupedCards[group];

        const items: any[] = [];

        const resultObject = {
          ...groupedCards[group][0],
          'so_no': groupedCards[group][0].docNo,
          'item_code': groupedCards[group][0].itemCode,
          'so_date': moment(groupedCards[group][0].date).format('DD/MM/YYYY'),
          'expected_delivery': moment(groupedCards[group][0].delivery_date).format('DD/MM/YYYY'),
          'numberDate': groupedCards[group][0].delivery_date,
          'customer_name': customerCards[
              customerCards.findIndex((a) =>
                  groupedCards[group][0].code === a.code,
              )
              ].companyName,
          'late_(days)': groupedCards[group][0].outstandingQuantity ?
              Math.ceil(moment.duration(moment().valueOf() - groupedCards[group][0].delivery_date).asDays()) :
              null,
          'os_item' : Math.ceil(groupedCards[group].filter((a) => a.outstandingQuantity > 0).length),
          'os_amount' : Math.ceil(
              (groupedCards[group][0].amount / groupedCards[group][0].quantity)
              * groupedCards[group][0].outstandingQuantity),
          'extras' : `- ${moment(cards[0].date).format('DD/MM/YYYY')}
						- ${gdbx.customerNames[cards[0].code]}`,
        };

        const detailObject = cards.map((i) => {
          if (i.outstandingQuantity) {
            return {
              'seq.': i.sequence,
              'customer': gdbx.customerNames[i.code],
              'docDate': i.date,
              'item': gdbx.stockItemNames[i.itemCode],
              'item_code': i.itemCode,
              'expected_delivery': i.outstandingQuantity
                  ? moment(i.delivery_date).format('DD/MM/YYYY')
                  : null,
              'unit_price': i.amount / i.quantity ? i.amount / i.quantity : 0,
              'disc.': i.discount ? Math.ceil(i.discount) : null,
              'os_amount':
                  i.outstandingQuantity === 0
                      ? 0
                      : (i.amount / i.quantity) * i.outstandingQuantity,
              'quantity': i.quantity,
              'os_quantity': i.outstandingQuantity,
              '_rowVariant': i.outstandingQuantity > 0 ? 'top-five' : '',
              'agent': i.agent,
            };
          } else {
              return null;
          }
        })
            .filter((item) => item !== null);

        if (detailObject.length) {
          items.push(...detailObject);
        }

        resultObject.items = items;

        resultObject.os_amount = resultObject.items.reduce((a, b) => a + b.os_amount, 0);

        result.push(resultObject);
      }
    }

    this.tableFields = [
      {
        key: 'late_(days)',
        sortable: false,
      },
      {
        key: 'expected_delivery',
        sortable: false,
      },
      {
        key: 'customer_name',
        sortable: false,
      },
      {
        key: 'os_item',
        sortable: false,
      },
      {
        key: 'os_amount',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'agent',
        sortable: false,
      },
      {
        key: 'so_date',
        sortable: false,
      },
      {
        key: 'so_no',
        sortable: false,
      },
      ];

    this.detailFields = [
      { key: 'seq.', label: 'Seq', sortable: true },
      {
        key: 'item_code',
        sortable: true,
      },
      {
        key: 'item',
        sortable: true,
      },
      {
        key: 'expected_delivery',
        sortable: true,
      },
      {
        key: 'os_quantity',
        label: 'O/S Qty',
        sortable: true,
      },
      {
        key: 'quantity',
        label: 'Qty',
        sortable: true,
      },
      {
        key: 'unit_price',
        sortable: true,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'disc.',
        sortable: true,
      },
      {
        key: 'os_amount',
        label: 'O/S Amt',
        sortable: true,
        formatter: TableItemFormatter.currency,
      },


    ];

    this.tableItems = result.filter((a) => {
        return a.os_amount > 0;
    }).sort((a, b) => b['late_(days)'] - a['late_(days)']);

    this.saveHistory('tableItems', 'tableFields');
  }
}
