
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import settingx from '@/store/modules/settingx';
import {nestedGroupBy} from '@/util/nestedGroupBy';

@Component({
  components: {
    TableFilterable,
  },
})
export default class FulfilledSalesOrder extends FilterWidget {
  public filterIds: Array<
    | 'date'
    | 'dateAsOf'
    | 'dateRange'
    | 'stockItems'
    | 'agents'
    | 'customers'
    | 'suppliers'
  > = [];

  public tableItems: any[] = [];
  public tableFields: any[] = [];

  public detailFields: any[] = [];

  public get permissionIds(): PermissionsGroup[] {
    return ['sales', 'customers'];
  }

  public get exportFileName() {
    return 'Top Customer' + '_';
  }

  public returnStatus(x) {
      if (x.delivery_date === x.last_invoice_date) {
        return 'On-Time';
      }
      if (x.delivery_date > x.last_invoice_date) {
        return 'Early';
      }
      if (x.delivery_date < x.last_invoice_date) {
        return 'Late';
      }
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedStockItems,
      selectedCustomers,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedStockItems,
      selectedCustomers,
    ]);
  }

  public async expensiveCalc() {
    const tradeRef = FilteredDatabase.ref('salesOrder')
        .dateRange(this.selectedDateRange, 'delivery_date');

    const customerRef = FilteredDatabase.ref('customers')
        .agents(this.selectedAgents);

    const stockRef = FilteredDatabase.ref('globalStocks');

    const stockCards = await (await stockRef.get()).getCards();

    const tradeCards = await (await tradeRef.get()).getCards();

    const customerCards = await (await customerRef.get()).getCards();

    const groupedCards = nestedGroupBy(tradeCards.filter((a) => a.outstandingQuantity === 0), ['docNo']);

    const result: any[] = [];

    for (const group in groupedCards) {

      if (groupedCards[group].reduce((a, b ) => {
        return a.outstandingQuantity + b.outstandingQuantity;
      })) {
        let detailedArray: any = [];

        groupedCards[group].forEach((invoice) => {
          if (invoice.quantity) {
            detailedArray.push(
                {
                  status: this.returnStatus(invoice),
                  unit_price: invoice.amount / invoice.quantity,
                  discount: invoice.discount ? invoice.discount : null,
                  item_desc: stockCards[stockCards.findIndex((a) => {
                    return a.itemCode === invoice.itemCode;
                  })].itemDesc,
                  quantity: invoice.quantity,
                  amount: invoice.amount,
                  expected_delivery: moment(invoice.delivery_date).format('DD/MM/YYYY'),
                  actual_delivery: moment(invoice.last_invoice_date).format('DD/MM/YYYY'),
                  days: Math.ceil(moment
                      .duration( invoice.last_invoice_date - invoice.delivery_date)
                      .asDays()),
                },
            );
          }
        });

        result.push({
          'so_no': groupedCards[group][0].docNo,
          'item_code': groupedCards[group][0].itemCode,
          'so_date': moment(groupedCards[group][0].date).format('DD/MM/YYYY'),
          'expected_delivery': moment(groupedCards[group][0].delivery_date).format('DD/MM/YYYY'),
          'actual_delivery': moment(groupedCards[group][0].last_invoice_date).format('DD/MM/YYYY'),
          'numberDate': groupedCards[group][0].delivery_date,
          'status':  this.returnStatus(groupedCards[group][0]),
          'days': Math.ceil(moment
              .duration( groupedCards[group][0].last_invoice_date - groupedCards[group][0].delivery_date)
              .asDays()),
          'items': detailedArray,
          'agent': groupedCards[group][0].agent,
          'c._name': customerCards[
              customerCards.findIndex((a) =>
                  groupedCards[group][0].code === a.code,
              )
              ].companyName,
        });

        detailedArray = [];
      }
    }

    this.tableFields = [
      {
        key: 'status',
        sortable: false,
      },
      {
        key: 'days',
        sortable: false,
      },
      {
        key: 'expected_delivery',
        sortable: false,
      },
      {
        key: 'actual_delivery',
        sortable: false,
      },
      {
        key: 'c._name',
        sortable: false,
      },
      {
        key: 'agent',
        sortable: false,
      },
      {
        key: 'so_date',
        sortable: false,
      },
      {
        key: 'so_no',
        sortable: false,
      },
    ];

    this.detailFields = [
      {
        key: 'status',
        sortable: false,
      },
      {
        key: 'days',
        sortable: false,
      },
      {
        key: 'expected_delivery',
        sortable: false,
      },
      {
        key: 'actual_delivery',
        sortable: false,
      },
      {
        key: 'item_desc',
        sortable: false,
      },
      {
        key: 'quantity',
        sortable: false,
      },
      {
        key: 'unit_price',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'discount',
        sortable: false,
      },
      {
        key: 'amount',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
    ];

    this.tableItems = result;

    this.saveHistory('tableItems', 'tableFields', 'detailFields');
  }
}
